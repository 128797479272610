function videoPreview() {
    document.querySelectorAll('.video-cover').forEach(poster=>{
        poster.addEventListener('click',function(e) {
            document.querySelectorAll('.video-review').forEach(review=>{
                
                let iframe = review.querySelector('iframe');
                let src = iframe.dataset.src;
                src = src.replace(/&autoplay=1$/, '');
                iframe.dataset.src = src;
                iframe.setAttribute('src',"");
                iframe.style.display = 'none';
                review.querySelector('.video-cover').style.display = 'block';
            })
            poster.closest('.video-review').querySelector('.video-cover').style.display = 'none';
            poster.closest('.video-review').querySelector('iframe').style.display = 'block';
            poster.closest('.video-review').querySelector('iframe').dataset.src +="&autoplay=1";
            poster.closest('.video-review').querySelector('iframe').setAttribute('src', poster.closest('.video-review').querySelector('iframe').dataset.src);
        })
    });
}
module.exports = videoPreview;