function modalBtn(handle) {
    const modalBtns = document.querySelectorAll('.modalBtn');
    modalBtns.forEach(btn=>{
        let projectModals = document.querySelectorAll('.project-modal');
        let modal = document.querySelector('.'+btn.dataset.expand);
        
        btn.addEventListener('click',function(e) {
            e.preventDefault();
            document.body.style.overflow = 'hidden';
            if(btn.dataset.expand!= 'overlay') {
                projectModals.forEach(modal=>{
                    modal.classList.remove('active');
                })
            }
            document.querySelector('.call-popup').classList.remove('active');
            modal.classList.add('active');
            handle();
        })
    })
}
module.exports = modalBtn;